<template>
    <div class="graph-line-percentual-container">
        <div v-if="!hasValue2" class="graph-line-percentual-title">
            {{  values[0].title }}
        </div>
        <div class="graph-line-percentual">
            <div class="graph-line-percentual-left" :style="`color: ${values[0].colorLegend};`">
                {{ values[0].percentual }}%
            </div> 
            <div class="graph-line-percentual-line-container">
                <div class="graph-line-percentual-line-left" :class="classPercentual100" :style="ccsVarsLeft" ></div>
                <div v-if="hasValue2" class="graph-line-percentual-line-right" :class="classPercentual100" :style="ccsVarsRight"></div>
            </div> 
            <div v-if="hasValue2" class="graph-line-percentual-right" :style="`color: ${values[1].colorLegend};`">
                {{ values[1].percentual }}%
            </div>
        </div>
        <div v-if="hasValue2 && showLegend" class="graph-line-percentual-legend">
            <div class="graph-line-percentual-legend-left">
                <v-badge offset-y="-1" dot left :color="values[0].color"></v-badge> {{ `${values[0].title} (${values[0].total})` }}
            </div>
            <div class="graph-line-percentual-legend-right">
                <v-badge offset-y="-1" dot left :color="values[1].color"></v-badge> {{ `${values[1].title} (${values[1].total})` }}
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'GraphLinePercentual',
    props: {
        title: {
            type: String,
            required: false,
        },
        showLegend: {
            type: Boolean,
            default: false
        },
        values: {
            type: Array,
            default: () => {
                return [
                    {
                        title: 'Item 1',
                        percentual: 25,
                        total: 100,
                        color: '#E5D545',
                        colorLegend: '#807726'
                    },
                    {
                        title: 'Item 2',
                        percentual: 75,
                        total: 100,
                        color: '#442d96',
                        colorLegend: '#442d96'
                    }
                ]
            }
        }
    },

    data() {
        return {
            
        }
    },

    computed: {
        hasTitle() {
            return ![undefined, null].includes(this.title);
        },
        hasValue2() {
            return ![undefined, null].includes(this.values[1]);
        },
        ccsVarsLeft() {
            let percentual = this.fixMinPercentualValue(this.values[0].percentual);
            return {
                '--line-graph-bg-color-left': this.values[0].color,
                '--line-graph-width-left': percentual + '%'
            }
        },
        ccsVarsRight() {
            if(!this.hasValue2) {
                return '';
            }
            let percentual = this.fixMinPercentualValue(this.values[1].percentual);
            return {
                '--line-graph-bg-color-right': this.values[1].color,
                '--line-graph-width-right': percentual + '%'
            };
        },
        classPercentual100() {
            if((((Number(this.values[0].percentual)) === 100) || ((Number(this.values[0].percentual)) === 0))) {
                return "graph-line-percentual-line-100";
            }
            return '';
        }
    },

    methods: {
        fixMinPercentualValue(percentual) {
            /* Devido a um bug visual causado pela distorção da borda o valor mínimo não pode ser um valor entre 0% e 0.5% */
            if(percentual > 0 && percentual < 0.5){
                percentual = 0.5;
            }
            return percentual;
        }
    }

}

</script>
<style lang="scss" scoped>
@import './src/design/variables.scss';
.graph-line-percentual-container{
    .graph-line-percentual-title{
        font-size: $font-size-xxxs;
        color: $neutral-color-high-medium;
    }
    .graph-line-percentual {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .graph-line-percentual-left, .graph-line-percentual-right {
            font-size: $font-size-xxxs;
        }
        .graph-line-percentual-line-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin: 0 $spacing-03;
            height: 14px;
            background-color: $neutral-color-low-dark;
            border-radius: $border-radius-xg;
            
            .graph-line-percentual-line-left {
                width: var(--line-graph-width-left);
                background-color: var(--line-graph-bg-color-left);
                height: 14px;
                border-top-left-radius: $border-radius-xg;
                border-bottom-left-radius: $border-radius-xg;
                &.graph-line-percentual-line-100 {
                    border-top-right-radius: $border-radius-xg;
                    border-bottom-right-radius: $border-radius-xg;       
                }
            }
            .graph-line-percentual-line-right {
                width: var(--line-graph-width-right);
                background-color: var(--line-graph-bg-color-right);
                height: 14px;
                border-top-right-radius: $border-radius-xg;
                border-bottom-right-radius: $border-radius-xg;
                &.graph-line-percentual-line-100 {
                    border-top-left-radius: $border-radius-xg;
                    border-bottom-left-radius: $border-radius-xg;
                }
            }
        }
    }

    .graph-line-percentual-legend {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        margin-top: $spacing-03;
        color: $brand-color-primary-medium;
        
        div {
            .v-badge {
                margin: 0 $spacing-03;
            }
        }
        .graph-line-percentual-legend-left {
            margin-right: $spacing-06;
            font-size: $font-size-xxs;
        }
        .graph-line-percentual-legend-right {
            font-size: $font-size-xxs;
        }
    }
}
</style>